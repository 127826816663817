/**
 * Slider
 */
export default class Slider {
  static columnCount = 0;
  constructor(parentSelector, itemSelector, arrowsSelector, delay, itemOffset, loop) {
    this.el = $(parentSelector);
    this.items = this.el.find(itemSelector);
    this.arrowLeft = $(arrowsSelector).find('.arrow-left');
    this.arrowRight = $(arrowsSelector).find('.arrow-right');
    this.itemWidth = this.items.eq(0).width();
    this.delay = delay;
    this.itemOffset = itemOffset;
    this.loop = (loop == null) ? false : loop;
    if (this.el.length > 0 && !loop) {
      this.disableArrows();
    }
  }

  /**
   * On window resize recalculate item width to offset it correctly
   * @param hiddenParent, if slider is in a non rendered div, can't calculate width, used to hidden it instead
   */
  recalculateItemWidth(hiddenParent) {
    if (hiddenParent !== null) {
      hiddenParent.css({ visibility: 'hidden', display: 'block' });
      this.itemWidth = this.items.eq(0).width();
      hiddenParent.css({ visibility: '', display: '' });
    } else {
      this.itemWidth = this.items.eq(0).width();
    }
  }

  /**
   * if slider don't loop, apply disabled class to the right arrow when scrolled to beginning or end.
   * @param direction
   */
  disableArrows(direction) {
    if (this.loop) { return; }

    let domEl = this.el.get(0);

    

    if (direction === 'left') {
      this.arrowRight.removeClass('disabled');
    }
    if (direction === 'right') {
      this.arrowLeft.removeClass('disabled');
    }

    /* remove arrows if not enough items to scroll */
    if (domEl.scrollWidth - domEl.offsetWidth === 0) {
      this.arrowRight.css('display', 'none');
      this.arrowLeft.css('display', 'none');
    } else { 
      this.arrowRight.css('display', 'block');
      this.arrowLeft.css('display', 'block');
    }

    if (domEl.scrollLeft === domEl.scrollWidth - domEl.offsetWidth) {
      this.arrowRight.addClass('disabled');
    }
    else if (domEl.scrollLeft === 0) {
      this.arrowLeft.addClass('disabled');
    }
  }


  /**
   * attach click event to arrows, call the scroll function.
   */
  scrollEvent() {
    this.arrowLeft.click(ev => this.scroll('left'));
    this.arrowRight.click(ev => this.scroll('right'));
    $(window).resize(() => {
        this.recalculateItemWidth($(this.parentSelector));
    })
  }

  /**
   * calculate and animate the scroll needed for the slider.
   * @param direction
   */
  scroll(direction) {
    let domEl = this.el.get(0);
    let offset = domEl.scrollLeft;
    let that = this;

    if (domEl.scrollLeft === domEl.scrollWidth - domEl.offsetWidth
      && direction === 'right' && this.loop) {
      offset = 0;
    } else if (domEl.scrollLeft === 0
      && direction === 'left' && this.loop) {
      offset = domEl.scrollWidth - domEl.offsetWidth;
    } else {
      offset = this.el.scrollLeft() +
        (direction === 'left'
          ? -this.itemOffset * this.itemWidth
          : this.itemOffset * this.itemWidth);
    }

    this.el.animate({
      scrollLeft: offset
    }, this.delay, "linear", function () {
      that.disableArrows(direction);
    });
  }
}
