import Utilities from "../utilities";

/**
 * class Header
 */
export default class Header {


  /**
   *
   * @param {string} mobileNavSelector
   */
  constructor(mobileNavSelector) {
    this.sidebar = $(mobileNavSelector);
  }

  /**
   *
   * @param {string} openSelector css selector to open mobile nav
   * @param {string} closeSelector css selector to close mobile nav
   */
  mobileNavEvent(openSelector, closeSelector) {
    $(openSelector).click(ev => {
      Utilities.toggle(this.sidebar);
      $('.overlay').addClass('active');
      this.sidebar.height('0');
      this.sidebar.animate({
        height: this.sidebar.get(0).scrollHeight
      }, 500, () => {
        this.sidebar.height('auto');
      })
    });

    var close = (ev) => {
      $('.overlay').removeClass('active');
      this.sidebar.animate({
        height: '0'
      }, 500, () => {
        Utilities.toggle(this.sidebar);
      })
    }

    $(closeSelector).click(close);

    $('.overlay').click(close);

  }

  /**
   * Event for desktop and mobile dropdown menu
   */
  dropdownEvent() {
    /* close dropdown when clicked outside */
    $(document).click(ev => {
      closeDropdown();
    });

    function closeDropdown(exception) {
      if (exception !== undefined)
        $('.nav .nav-dropdown').not(exception).hide();
      else
        $('.nav .nav-dropdown').hide();
    }

    $('.rse-club-dropdown-btn').click(ev => {
      ev.stopPropagation();
      // $('#club-dropdown-btn').toggleClass('text-red');
      closeDropdown('.rse-dropdown');
      Utilities.toggle($('.rse-dropdown'));
    });

    $('.members-club-dropdown-btn').click(ev => {
      ev.stopPropagation();
      // $('#club-dropdown-btn').toggleClass('text-red');
      closeDropdown('.members-dropdown');
      Utilities.toggle($('.members-dropdown'));
    });


    $('.dropdown-toggle').click(function(ev) {
      $(this).toggleClass('open');
      Utilities.toggle($(this).parent().find('.nav-dropdown-menu'), 'flex');
    });
  }


/**
 * Event for user popover, containing links for profile editing/disconnect or login/signup
 */
  userPopoverEvent() {
    $('#user-modal-btn').popover({
        html: true,
        container: '.header',
        trigger: 'focus',
        placement: 'bottom',
        offset: '0, 10px',
        // boundary: '.header',
        content: $('#popover-user-content').html(),
      }
    )
    $('#user-modal-btn').on('shown.bs.popover', function() {
      if($(window).width() < 768) {
        $('.header .popover').attr('style', "top: " + Math.trunc($(this).offset().top + 25) + "px !important")
      }
    });
  }
}
