/**
 * class Map
 */
export default class Map {
  /**
   *
   * @param region_selector
   * @param container_selector
   * @param logo_path
   * @param offset
   */
  constructor(region_selector, container_selector, logo_path, offset = '0, 0') {
    this.container = $(container_selector);
    this.regions = $(region_selector);
    this.logo = "<img alt='logo fnce' src=" + logo_path + "/>";
    this.offset = offset;
  }

  postalCodeEvent() {
    let that = this;
    $('#postal-code-form').submit(ev => {
      $.get('zip_codes/search.json?code=' + $('#postal_code').val(),
        function (data) {
          let found = false, i = 0, result = null;
          while (!found) {
            if (that.regions.eq(i).data('id') == data.zone.id) {
              found = true;
              result = that.regions.eq(i);
            }
            i++;
          }
          if (result != null)
            result.popover('show');
          result.focus();
        })
      return false;
    })
  }

  /**
   * zonePopovers
   * use Popover.js to display a zone picking modal
   */
  popoversEvent() {
    let that = this;

    this.regions.each(function () {

      let content = '';
      content += '<a class="map-popover-link" href="' + that.container.data('url') + '/' + $(this).data('id') + '">' + that.logo + '<span>';
      content += $(this).data('region');
      content += '</span></a>';

      /* Position CEPAC Popover */
      if ($(this).data('id') === 17) {
        $(this).on('inserted.bs.popover', function () {
          $('.sub-region').hide();
        });

        $(this).on('shown.bs.popover', function () {
          $('.sub-region').show();
        });
      }

      $(this).popover({
        container: that.container,
        placement: 'top',
        trigger: 'focus',
        content: content,
        html: true
      })

      /*already clicked region when clicked again redirect */
      $(this).on('shown.bs.popover', function () {
        //click on the link inside the popover
        $('.map-popover-link').click(function (e) {
          $('.spinner-overlay').css('display', 'flex');
        });
        // change the href directly to force the passage to the zone controller which doesn't occur when changing the window.href
        $(this).attr("href", that.container.data('url') + '/' + $(this).data('id'));
        //click on the link previously updated inside the map region
        $(this).click(function (e) {
          $('.spinner-overlay').css('display', 'flex');
        });
      })
      $(this).on('hide.bs.popover', function () {
        $(this).unbind('click');
        $(this).attr('href', 'javascript:void(0)');
      })
    });
  }
}
