// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require("jquery3");
//= require("popper");
//= require("bootstrap-sprockets");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
let Masonry = require('masonry-layout');
let jQuery = require("jquery");
let jQueryUi = require("jquery-ui-dist/jquery-ui");
let jQueryBridget = require('jquery-bridget');

// make Masonry a jQuery plugin
jQueryBridget('masonry', Masonry, jQuery);

global.$ = global.jQuery = jQuery, jQueryUi;
window.$ = window.jQuery = jQuery, jQueryUi;

require("bootstrap");
require("packs/vendor/clamp.min.js");
require("packs/events");

document.addEventListener("turbolinks:load", () => {
  /* fit text in .block div */
  $('.block p').fitText(1.5);

  /* clamp card text (multiline text-ellipsis) */
  $('.card-text').each(function () {
    if ($(this).hasClass('shorter')) {
      $clamp(this, {clamp: '2', useNativeClamp: true})
    } else {
      $clamp(this, {clamp: '4', useNativeClamp: true})
    }
  })

  /* clamp slider half height card title on on line (multiline text-ellipsis) */
  $(`.card-h50-bottom .card-title,
  .card-h50-top .card-title,
  .card-h50-bottom .card-category,
  .card-h50-top .card-category`).each(function () {
    $clamp(this, {clamp: '1', useNativeClamp: true})
  })

  $(`.card-h100 .card-title`).each(function () {
    $clamp(this, {clamp: '4', useNativeClamp: true})
  })

  $('.card-info .card-title').each(function () {
    $clamp(this, {clamp: '2', useNativeClamp: true})
  })

  /* clamp media-full card title (multiline text-ellipsis) */
  $('.media-article-full h2').each(function () {
    $clamp(this, {clamp: '5', useNativeClamp: true})
  })

  $('.media-article-third h2').each(function () {
    $clamp(this, {clamp: 'auto', useNativeClamp: true})
  })

  $('.block-b h2').each(function () {
    $clamp(this, {clamp: '3', useNativeClamp: true})
  })


  /* clamp home info and page block (multiline text-ellipsis) */
  $('#home-club .block-body > p').each(function () {
    if ($(this).contents().length == 0) {
      $(this).remove();
    }
    $clamp(this, {clamp: 'auto', useNativeClamp: true})
  })

  $('.club-intro').each(function () {
    $clamp(this, {clamp: '4'})
  })

  $('.recaptchaAllowed').click(function(){
    location.reload();
  });

  $(document).on('click', '.toggle-password', function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $($(this).attr("toggle"));
    console.log(input)
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  /* rearrange masonry in .card-columns Social Wall to be read from left to right */
  // var _wrapper = $(".card-columns"),
  //   _cards = $(".card"),
  //   _cols = Number(_wrapper.css("column-count")),
  //   _out = [],
  //   _col = 0;
  // /* if not mobile */
  // if (_cols > 1) {
  //   while (_col < _cols) {
  //     for (var i = 0; i < _cards.length; i += _cols) {
  //       var _val = _cards[i + _col];
  //       if (_val !== undefined)
  //         _out.push(_val);
  //     }
  //     _col++;
  //   }
  // } else {
  //   _out = _cards;
  // }
  // _wrapper.html(_out);

  /* in news body, add arrow to links which are alone */
  $('.article-body p').each(function (index) {
    if (this.childNodes.length > 0) {
      if (this.childNodes[0].tagName == 'A' && this.childNodes[0].classList.length == 0) {
        $(this.childNodes[0]).addClass('link-arrow');
      }
    }
  })

  // $('.article-body iframe').wrap('<div class="embed-media"></div>')

  /* for mobile club header, display a white block in front of the grey background, to match mockups */
  $('.club-header .bg-whiter').css('height', $('.block-b').height() / 2 + 15)

  /** IE OBJECT FIT WORKAROUND **/
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    $('.img-object-fit').each(function () {
      var t = $(this),
        s = 'url(' + t.attr('src') + ')',
        p = t.parent(),
        d = $('<div></div>');

      p.append(d);
      d.css({
        'height': t.parent().css('height'),
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': '50% 50%',
        'background-image': s,
        'border-radius': $(this).hasClass('card-img') || p.hasClass('card-img-top') ? '5px' : '0'
      });
      t.hide();
    });
  }
});

/*global jQuery */
/*!
* FitText.js 1.2
*
* Copyright 2011, Dave Rupert http://daverupert.com
* Released under the WTFPL license
* http://sam.zoy.org/wtfpl/
*
* Date: Thu May 05 14:23:00 2011 -0600
*/

(function ($) {

  $.fn.fitText = function (kompressor, options) {

    // Setup options
    var compressor = kompressor || 1,
      settings = $.extend({
        'minFontSize': Number.NEGATIVE_INFINITY,
        'maxFontSize': Number.POSITIVE_INFINITY
      }, options);

    return this.each(function () {

      // Store the object
      var $this = $(this);

      // Resizer() resizes items based on the object width divided by the compressor * 10
      var resizer = function () {
        $this.css('font-size', Math.max(Math.min($this.width() / (compressor * 10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)));
      };

      // Call once to set.
      resizer();

      // Call on resize. Opera debounces their resize by default.
      $(window).on('resize.fittext orientationchange.fittext', resizer);

    });

  };
})(jQuery);


/**
 *
 *Gestion des cookies simplifiée
 *
 **/


window.cookies = {
  set: function (name, value, days) {
    if (name) {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toUTCString();
      } else {
        var expires = "";
      }
      document.cookie = name + "=" + escape(value) + expires + "; path=/";
    }
  },
  get: function (name) {
    if (name) {
      var match = document.cookie.match(new RegExp(name + "=(.*?)(?:;|$)"));
      if (match) {
        return unescape(match[1].replace(/\+/g, "%20"));
      }
    }
  },
  list: function () {
    var matches = document.cookie.split(new RegExp("=|; "));
    if (matches.length > 1) {
      var cookies = new Array(matches.length / 2);
      for (var i = j = 0; j < matches.length; j += 2) {
        cookies[i++] = matches[j]
      }
      return cookies;
    }
  },
  delete: function (name) {
    this.set(name, "", -1);
  }
}


/** TEMP */
