export default class Forms {
  constructor(signup_form, update_form ) {
    this.signupForm = $(signup_form);
    this.updateForm = $(update_form);
  }

  stepEvent() {
    this.signupForm.find('.nav-link').click( e => {
      this.signupForm.find('.nav-item').removeClass('active');
      $(e.target).parent().addClass('active');
    });
  }

  cardEvent() {
    $('.account-card-container img').click((e) => {
      let check = $('.account-card-container').find('#check-circle');
      $('#customer_card_version').prop('value',e.target.getAttribute('src').match(/version_([0-9])/)[1])
      $('#card-image-preview').prop('src', e.target.getAttribute('src'));
      $(e.target).before(check);
    });
  }
}
