import Header from './modules/header'
import Slider from './modules/slider'
import Form from './modules/form'
import Map from './modules/map'
import Utilities from "./utilities";

document.addEventListener("turbolinks:load", () => {

  /* Default popover elements whitelist */
  var DefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList
  DefaultWhiteList.a.push('data-target');
  DefaultWhiteList.a.push('data-toggle');


  let header = new Header('.nav-mobile-menu');
  header.dropdownEvent();
  // header.userPopoverEvent();
  // header.mobileNavEvent('#nav-burger', '#nav-close');

  let masonrySlider = new Slider(
    '.slider-masonry',
    '.card',
    '.slider-masonry-arrows',
    500, 2, false);

  masonrySlider.scrollEvent();

  $("a[href='#j-accepte-les-cookies'],a[href='#je-refuse-les-cookies']").on("click",function(e){
    e.preventDefault();
    e.stopPropagation();
    window.cookies.set('accepted_cookie', 1)
    $('.cookies.row').hide()
    $('#content').css('margin-top', $('.fixed-header').height())
  });

  let categorySlider = new Slider(
    '.slider-category',
    '.nav-item',
    '.nav-category',
    500, 2, false);
  categorySlider.scrollEvent();

  let form = new Form('#signup-form');
  form.stepEvent();
  form.cardEvent();

  let map = new Map('.region',
    '.map-region',
    '/assets/icons/picto_ce_48x48.png',
     );
  map.postalCodeEvent();
  map.popoversEvent();
});
